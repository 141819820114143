// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageBackground{
  width: 100vw;
  height: 100vh;
  background-color: #111111;
}

.fullPage{
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.webpage{
  flex: 1 1;
  height: 100vh;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,cAAc;AAChB","sourcesContent":[".pageBackground{\n  width: 100vw;\n  height: 100vh;\n  background-color: #111111;\n}\n\n.fullPage{\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  overflow: auto;\n}\n\n.webpage{\n  flex: 1;\n  height: 100vh;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
