// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageBody{
  flex: 1 1;
  margin: 0px 15px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #464646;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}

::-webkit-scrollbar-corner{
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/GenericPage/page.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,gBAAgB;AAClB;;AAEA,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,uBAAuB;AACzB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".pageBody{\n  flex: 1;\n  margin: 0px 15px;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #464646;\n  border-radius: 5px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #6e6e6e;\n}\n\n::-webkit-scrollbar-corner{\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
