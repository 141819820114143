// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbarBox {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 25px 15px;
  caret-color: transparent;
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #111111;
}

.googleLoginButton {
  width: 32px;
  height: 32px;
  caret-color: transparent;
}

.sidebarToggleBars {
  color: white;
  scale: 1.2;
  caret-color: transparent;
  padding-top: 3px;
}

.sidebarToggleBars:hover {
  cursor: pointer;
  color: #3aa3f5;
}

.googleLogo {
  object-fit: contain;
  max-height: 32px;
  border-radius: 16px;
  outline: 2px solid #3aa3f5;
}

.googleLogo:hover {
  outline: 2px solid white;
  cursor: pointer;
}

.googleLogoutButton {
  height: 25px;
  width: 72px;
  border-radius: 2px;
  outline: 1px solid #3aa3f5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #181818;
  cursor: pointer;
  caret-color: transparent;
}

.googleLogoutButton:hover {
  outline: 1px solid white;
}

.googleLogoutText {
  padding: 0px 3px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/topbar/topbar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,gBAAgB;EAChB,QAAQ;EACR,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".topbarBox {\n  height: 50px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 5px 15px 25px 15px;\n  caret-color: transparent;\n  position: sticky;\n  top: 0px;\n  z-index: 999;\n  background-color: #111111;\n}\n\n.googleLoginButton {\n  width: 32px;\n  height: 32px;\n  caret-color: transparent;\n}\n\n.sidebarToggleBars {\n  color: white;\n  scale: 1.2;\n  caret-color: transparent;\n  padding-top: 3px;\n}\n\n.sidebarToggleBars:hover {\n  cursor: pointer;\n  color: #3aa3f5;\n}\n\n.googleLogo {\n  object-fit: contain;\n  max-height: 32px;\n  border-radius: 16px;\n  outline: 2px solid #3aa3f5;\n}\n\n.googleLogo:hover {\n  outline: 2px solid white;\n  cursor: pointer;\n}\n\n.googleLogoutButton {\n  height: 25px;\n  width: 72px;\n  border-radius: 2px;\n  outline: 1px solid #3aa3f5;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 12px;\n  background-color: #181818;\n  cursor: pointer;\n  caret-color: transparent;\n}\n\n.googleLogoutButton:hover {\n  outline: 1px solid white;\n}\n\n.googleLogoutText {\n  padding: 0px 3px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
