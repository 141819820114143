// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarBox{
  width: 225px;
  height: calc(100vh - 20px);
  background-color: #212121;
  position: sticky;
  top: 0;
  z-index: 1500;
  caret-color: transparent;
  transition: all 300ms ease;
}


.sidebarLinksBox{
  padding-top: 5px;
}

.sidebarPageLinks{
  color: white;
  font-size: 20px;
  padding-top: 10px;
  transition: all 100ms ease;
}

.sidebarPageLinks:hover{
  color: #3aa3f5;
  cursor: pointer;
}

.titleHeaderBox{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  color: #3aa3f5;
  caret-color: transparent;
  line-break:unset;
}

.titleHeaderIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 1.4;
  padding-right: 6px;
}

.titleHeader{
  font-weight: 500;
  font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,yBAAyB;EACzB,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,wBAAwB;EACxB,0BAA0B;AAC5B;;;AAGA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,gCAAgC;EAChC,cAAc;EACd,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".sidebarBox{\n  width: 225px;\n  height: calc(100vh - 20px);\n  background-color: #212121;\n  position: sticky;\n  top: 0;\n  z-index: 1500;\n  caret-color: transparent;\n  transition: all 300ms ease;\n}\n\n\n.sidebarLinksBox{\n  padding-top: 5px;\n}\n\n.sidebarPageLinks{\n  color: white;\n  font-size: 20px;\n  padding-top: 10px;\n  transition: all 100ms ease;\n}\n\n.sidebarPageLinks:hover{\n  color: #3aa3f5;\n  cursor: pointer;\n}\n\n.titleHeaderBox{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #dddddd;\n  color: #3aa3f5;\n  caret-color: transparent;\n  line-break:unset;\n}\n\n.titleHeaderIcon{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  scale: 1.4;\n  padding-right: 6px;\n}\n\n.titleHeader{\n  font-weight: 500;\n  font-size: 26px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
